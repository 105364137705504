<template>
  <div>
    <van-action-sheet v-model="visbile" :title="title" @close="closeAction">
      <div class="content">
        <van-radio-group v-model="emitVal" @change="changeHandle">
          <van-radio v-for="(item,index) in dataList" :key="index" :name="item.val">
            <template #icon="props">
              <li :class="{'is-active':props.checked}" class="list-item">
                {{ item.name }}
              </li>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  props: {
    nameString: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visbile: false,
      emitVal: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.emitVal = this.value + ''
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.visbile = true
    },
    changeHandle() {
      const item = this.dataList.find(item => { return item.val + '' === this.emitVal + '' })
      this.$emit('input', this.emitVal, item.name, this.nameString)
      this.visbile = false
    },
    closeAction() {
      this.$emit('closeAction')
    }

  }
}
</script>
<style lang="scss" scoped>
:deep(.van-action-sheet) {
  padding: 0 32px;
  min-height: 608px;
}
:deep(.van-action-sheet__header){
  height: 118px;
  line-height: 118px;
  font-size: 32px;
  text-align: left;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
  border-bottom: 1px solid #F0F0F0;
}
.content{
  .list-item{
    height: 78px;
    line-height: 78px;
    border-bottom: 1px solid #F0F0F0;
    font-size: 26px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #333333;
    position: relative;
  }
  .van-radio:last-child .list-item{
    border: none;
  }
  // .list-item.is-active{
  //   color: #0BB8E4FF;
  // }
  .list-item.is-active::after{
    content:'';
    position:absolute;
    width: 32px;
    height: 32px;
    background: url(~@/assets/images/public/selected-icon.png) no-repeat center center;
    background-size: 100%;
    right: 0;
    top: 50%;
    margin-top: -16px;
  }
}
:deep(.van-action-sheet__close::before){
  display: none;
}
:deep(.van-action-sheet__close::after){
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  right: 32px;
  top: 48px;
  background: url(~@/assets/images/public/close-icon.png) no-repeat center center;
  background-size: 100%;
}
ul,li{
  list-style: none;
}
:deep(.van-radio){
  display: initial;
}
:deep(.van-radio__icon){
  height: auto;
}
</style>
