import { render, staticRenderFns } from "./infoContent.vue?vue&type=template&id=261c5183&scoped=true&"
import script from "./infoContent.vue?vue&type=script&lang=js&"
export * from "./infoContent.vue?vue&type=script&lang=js&"
import style0 from "./infoContent.vue?vue&type=style&index=0&id=261c5183&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261c5183",
  null
  
)

export default component.exports