<template>
  <div class="main-content">
    <div class="top-header">
      <header-base v-if="showHead" :brgb="brgb" :use-bstyle="useBstyle" :rgb="rgb" :gradient="gradient" :no-heade-bg="noHeadeBg" @headerBack="headerBack" />
      <div v-if="showHead" :class="[smalTitle]" class="child-title">{{ $t($route.meta.title) }}</div>
      <img class="header-img" :src="headerImg" alt="">
    </div>
    <div id="isContent" class="is-content">
      <info-steps v-if="useSteps" ref="info-steps" :step-num="stepNum" class="info-steps" />
      <slot></slot>
      <submit-btn v-if="needSubBtn" :btn-remark="btnRemark" :need-gray="needGray" :sub-text="subText" :submit-flag="submitFlag" @submit="submit" @graySubmit="graySubmit"/>
    </div>
  </div>
</template>

<script>
import InfoSteps from '@/components/infoSteps.vue'
import headerBase from "./header";
export default {
  
  components: {
    InfoSteps,
    headerBase
  },
  props: {
    showHead: {
      type: Boolean,
      default: true
    },
    subText: {
      type: String,
      default: () => {
        return ''
      }
    },
    btnRemark: {
      type: String,
      default: () => {
        return ''
      }
    },
    submitFlag: {
      type: Boolean,
      default: false
    },
    needSubBtn: {
      type: Boolean,
      default: true
    },
    stepNum: {
      type: String,
      default: ''
    },
    gradient: {
      type: Boolean,
      default: true
    },
    rgb: {
      type: String,
      default: () => {
        return '28, 29, 33'
      }
    },
    brgb: {
      type: String,
      default: () => {
        return '0, 95, 233'
      }
    },
    useBstyle: {
      type: Boolean,
      default: false
    },
    noHeadeBg: {
      type: Boolean,
      default: false
    },
    smalTitle: {
      type: String,
      default: ''
    },
    headerImg:{
      type: String,
      default: ''
    },
    needGray: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      useSteps: false // 有项目需要放开改改样式就ok
    }
  },

  methods: {
    submit() {
      this.$emit('submit')
    },
    graySubmit() {
      this.$emit('graySubmit')
    },
    headerBack() {
      this.$emit('headerBack')
    }
  }
}
</script>

<style lang="scss" scoped>
.top-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background: #F4F5FC !important;
  height: 200px;
  overflow: hidden;

  .header-img{
    position: absolute;
    width: 480px;
    height: 380px;
    top: -10px;
    right: 0;
    z-index: 5;
    // background: url('~@/assets/images/baseInfo/baseinfo-top-icon.png') no-repeat center center;
    // background-size: 480px; 
  }
}
.is-content{
  margin-top: 88px;
  .info-steps{
    margin-top: 48px;
  }
  .data-form{
    // margin-top: 48px;
    :deep(.form-item){
      padding: 0;
      position: relative;
      width: 100%;
      margin-bottom: 22px;
      height: 100px;
      background: #F7F7F7;
      border-radius: 16px;
    }
    :deep(.form-item.form-item-email .van-field__control){
      position: initial;
    }

    :deep(.form-item.form-item-email .van-field__button){
      display: flex;
      align-items: center;
      height: 100%;
    }
    :deep(.van-field--error){
      // border: 1px solid red;
      .van-field__control{
        color: red!important;
      }
    }
    :deep(.form-item.is-select .van-field__control){
      padding-right: 60px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

     :deep(.van-field__label){
        z-index: -1;
        position: absolute;
        top: 44px;
        left: 32px;
        font-size: 20px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #999999;
        width: 100%;
        // display: none;
        transition: all .5s;
        overflow: hidden;
      }
       :deep(.van-field__control){
        position: absolute;
        top: 0!important;
        bottom: 0;
        left: 0;
        transition: top .5s;
        font-size: 24px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #1F1F39!important;
        text-indent: 40px;
      }
      :deep(.focus-input .van-field__label){
        z-index: 1;
        top: 12px;
      }
      :deep(.focus-input .van-field__control){
         top:66px;
      }
    :deep(.focus-input) {
       :deep( .van-field__label){
        width: 100%;
      }
       :deep(.van-field__control){
        top: 50px;
      }
    }
    :deep(.is-select.focus-input .van-field__control){
      top:66px;
    }

  }
}
/deep/ .headerbase{
  position: fixed;
  top: 0;
  left: 0;
}
.child-title{
  position: fixed;
  top: 88px;
  left: 0;
  width: 100%;
  height: 72px;
  font-size: 48px;
  font-weight: 400;
  color: #1C1D21;
  line-height: 72px;
  // padding-top: 8px;
  margin-bottom: 32px;
  padding-left: 40px;
}
</style>
