<template>
  <div>
    <info-content
      step-num="second-step"
      :header-title="$t('navbar.contactInfo')"
      :sub-text="$t('navbar.submitbtn')"
      :btn-remark="$t('navbar.safeInfo')"
      :submit-flag="submitFlag"
      @submit="submit"
    >
      <div class="data-form">
        <van-form>
          <div class="item-title">{{ $t('navbar.emergencyContact') }}</div>
          <van-field v-model="contactRelationShipName" :class="{'has-val':String(dataForm.contactRelationShip).length}" class="form-item is-select" disabled :placeholder="$t('navbar.contactRelationship')" @click="showPop('contactRelationship')" />
          <van-field v-model="dataForm.contactRelationTel" maxlength="15" type="digit" class="form-item" :label="$t('navbar.telephone')" :placeholder="$t('navbar.telephone')" />
          <div class="item-title">{{ $t('navbar.alternateContact') }}</div>
          <van-field v-model="alternateContactShipName" :class="{'has-val':String(dataForm.alternateContactShip).length}" class="form-item is-select" disabled :placeholder="$t('navbar.contactRelationship')" @click="showPop('alternateContact')" />
          <van-field v-model="dataForm.alternateContactTel" maxlength="15" type="digit" class="form-item m0" :label="$t('navbar.telephone')" :placeholder="$t('navbar.telephone')" />
        </van-form>
      </div>
    </info-content>
    <pop-action ref="contactRelationship" v-model="dataForm.contactRelationShip" name-string="contactRelationShipName" :title="$t('navbar.contactRelationship')" :data-list="$t('navbar.emergencyContactList')" @input="changeHandle" />
    <pop-action ref="alternateContact" v-model="dataForm.alternateContactShip" name-string="alternateContactShipName" :title="$t('navbar.contactRelationship')" :data-list="$t('navbar.alternateContactList')" @input="changeHandle" />
  </div>
</template>

<script>
import InfoContent from '../components/infoContent.vue'
import perfectInformation from '../perfectInformation'
import PopAction from '../components/popAction.vue'

import { getBasiccontPage, getSubContactsInfo } from '@/api/axios'
import { validRelationTel } from '@/utils/validate'

export default {
  components: { InfoContent, PopAction },
  mixins: [perfectInformation],
  data() {
    return {
      contactRelationShipName: '',
      alternateContactShipName: '',
      dataForm: {
        contactRelationShip: '', // 紧急联系人关系
        contactRelationTel: '', // 紧急联系人电话
        alternateContactShip: '', // 备用联系人关系
        alternateContactTel: ''// 备用联系人电话
      }
    }
  },
  created() {
    this.getBasiccontPage()
  },
  methods: {
    submit() {
      if (validRelationTel(this.dataForm.contactRelationTel) && validRelationTel(this.dataForm.alternateContactTel)) {
        this.validateForm()
      }
    },

    // 校验字段是否符合规则
    validateForm() {
      const iphone = this.$store.state.settings.andirodData.iphone || ''
      let relationTel = [this.dataForm.contactRelationTel, this.dataForm.alternateContactTel]
      relationTel = Array.from(new Set(relationTel))

      if (relationTel.length < 2) {
        this.$toast({
          type: 'fail',
          message: this.$t('navbar.asIphoneError'),
          className: 'toast-width',
          duration: 3000
        })
        return
      }
      if (relationTel.some(item => { return item + '' === iphone + '' })) {
        this.$toast({
          type: 'fail',
          message: this.$t('navbar.asLoclIphoneError'),
          className: 'toast-width',
          duration: 3000
        })
        return
      }
      this.dataFormSubmit()
    },

    // 提交填写数据
    async dataFormSubmit() {
      const params = {
        emergency_contactlist: [
          { // 紧急联系人
            relationship: this.dataForm.contactRelationShip, // 关系
            phone: this.dataForm.contactRelationTel// 手机号
          },
          { // 备用联系人
            relationship: this.dataForm.alternateContactShip, // 关系
            phone: this.dataForm.alternateContactTel// 手机号
          }
        ]
      }
      const { inyimbo } = await getSubContactsInfo(params) || {}
      // 提交成功跳转路由
      if (inyimbo + '' === '200') {
        this.isSubMitPoint = true
        this.$router.push({ path: '/bankInfo' })
      }
    },

    // 获取反显信息
    async getBasiccontPage() {
      const { data } = await getBasiccontPage() || {}
      // 接口给反着送回来了
      this.dataForm.contactRelationShip = data[1].relationship
      this.dataForm.contactRelationTel = data[1].phone
      this.dataForm.alternateContactShip = data[0].relationship
      this.dataForm.alternateContactTel = data[0].phone

      this.contactRelationShipName = (this.$t('navbar.emergencyContactList').find(item => { return item.val === this.dataForm.contactRelationShip + '' })).name
      this.alternateContactShipName = (this.$t('navbar.alternateContactList').find(item => { return item.val === this.dataForm.alternateContactShip + '' })).name
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.btn-box){
  padding-top: 143px;
}
.is-select{
  position:relative;
  &::before{
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top:38px;
    right:32px;
    background: url(~@/assets/images/baseInfo/select-icon.png) no-repeat center center;
    background-size:100%;
  }
}
:deep(.has-val .van-field__control:disabled){
  -webkit-text-fill-color: initial;
}

.item-title{
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
  margin-bottom: 24px;
}

</style>
<style>
.toast-width{
  width: 50%;
}
.toast-width .van-toast__text{
  line-height: 34px;
}
</style>
